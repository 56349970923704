@import '../../scss/index.scss';
.navbar_top {
    position: fixed;
    z-index: 1000;
    background: #000000 !important;
    display: flex;
    flex-direction: column;
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .1);
    /*  border-bottom: 3px solid #69C528; */
    height: 130px !important;
    .line {
        width: 100%;
        height: 3px;
        background: radial-gradient(circle, #69C528, transparent);
        filter: brightness(150%);
    }
    .container {
        padding: 3px 5%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        .logo {
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                padding-top: 20px;
                width: 300px;
                height: auto;
            }
        }
        .nav_bottom {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 10px;
            .navBar {
                /*   margin-top: -90px; */
                a {
                    cursor: pointer;
                    font-family: $gotica;
                    font-size: 1.4 rem;
                    color: white !important;
                    margin: 0 1rem;
                    text-decoration: none;
                    transition: 0.3s ease-in;
                    font-weight: bold;
                    &:hover {
                        color: #69C528 !important;
                        text-decoration: none;
                        text-shadow: 0 0 10px #69C528, 0 0 20px #69C528, 0 0 30px #69C528;
                        /* Efecto de sombra de texto */
                        filter: brightness(150%);
                        /* Aumento de brillo */
                    }
                }
            }
            .icons {
                .cuenta {
                    background-color: $corporativo;
                    border-radius: 3px;
                    padding: 5px;
                    border: 1px solid $corporativo;
                    color: white;
                    cursor: pointer;
                    &:hover .opciones_user {
                        display: block;
                        padding: 5px;
                        margin-top: 10px;
                        background-color: white;
                        position: absolute;
                        li {
                            list-style: none;
                        }
                    }
                }
                a {
                    font-family: $title;
                    font-size: 1.7 rem;
                    color: var(--black);
                    /* margin: 0 1rem; */
                    text-decoration: none;
                    transition: 0.3s ease-in;
                    &:hover {
                        text-decoration: none;
                        text-shadow: 0 0 10px #69C528, 0 0 20px #69C528, 0 0 30px #69C528;
                        /* Efecto de sombra de texto */
                        filter: brightness(120%);
                    }
                }
                .opciones_user {
                    border: 1px solid $corporativo;
                    display: none;
                    padding: 5px;
                    margin-top: 10px;
                    background-color: black !important;
                    position: absolute;
                    transition: .3s ease-in;
                    li {
                        list-style: none;
                        a {
                            cursor: pointer;
                            color: $corporativo;
                        }
                    }
                }
                .mostrar {
                    display: block;
                    padding: 5px;
                    margin-top: 10px;
                    background-color: white;
                    position: absolute;
                    li {
                        list-style: none;
                        a {
                            cursor: pointer;
                        }
                    }
                }
            }
        }
        .menu-bar {
            display: none;
            cursor: pointer;
            svg {
                width: 25px;
                height: 25px;
                color: $corporativo;
            }
        }
    }
    @media screen and( max-width: $media-breackpoint-up-lg) {
        height: 90px !important;
        .container {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;
            .logo {
                img {
                    padding-top: 10px;
                    width: 200px;
                }
            }
            .menu-bar {
                display: block;
            }
            .navBar {
                opacity: 0%;
                position: absolute;
                top: 100%;
                left: -120%;
                width: 25% !important;
                height: 100vh;
                background: black !important;
                border-top: 1rem solid rgba(0, 0, 0, .1);
                transition: 0.5s ease-in;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                a {
                    font-size: 18px !important;
                    background: black !important;
                    display: block;
                    text-align: start !important;
                    font-family: $gotica !important;
                    padding-top: 15px;
                    padding-bottom: 15px;
                    font-weight: lighter !important;
                }
            }
            .icons {
                margin-top: 20px !important;
                .cuenta {
                    margin-left: auto;
                    background-color: white;
                    border-radius: 3px;
                    padding: 2px;
                    border: 1px solid $corporativo;
                    cursor: pointer;
                }
            }
            .nav__active {
                transform: translateX(0);
                transition: 0.5s ease-in-out;
                opacity: 100%;
                left: 0%;
            }
            @media screen and( max-width: $media-breackpoint-up-sm) {
                .logo {
                    img {
                        padding-top: 10px;
                        width: 120px;
                    }
                }
                .icons {
                    .cuenta {
                        background-color: white;
                        border-radius: 3px;
                        font-size: 12px;
                        padding: 2px;
                        border: 1px solid $corporativo;
                        cursor: pointer;
                    }
                    svg {
                        margin-left: 0.5rem !important;
                        width: 20px !important;
                        height: 20px !important;
                    }
                }
            }
        }
    }
    /*  @media screen and( max-width: $media-breackpoint-up-md-bootstrap) {
        .container {
            padding: 3px 5%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .navBar {
                a {
                    font-family: $title;
                    font-size: 1.7 rem;
                    color: var(--black);
                    margin: 0 1rem;
                    text-decoration: none;
                    transition: 0.3s ease-in;
                    &:hover {
                        color: blueviolet;
                        text-decoration: none;
                    }
                }
            }
            .icons {
                .cuenta {
                    background-color: white;
                    border-radius: 3px;
                    font-size: 12px;
                    padding: 2px;
                    border: 1px solid $corporativo;
                    cursor: pointer;
                }
                svg {
                    margin-left: 0.5rem !important;
                    width: 20px !important;
                    height: 20px !important;
                }
            }
        }
    } */
}