@import '../../scss/index.scss';
.no_data_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    img {
        width: 30%;
        height: auto;
    }
    h2 {
        text-align: center;
        font-family: $title;
        color: $corporativo;
    }
}