@import '../../../scss/index.scss';
.container__paymethod {
    .row {
        .funciones_producto {
            padding-top: 5px;
            display: flex;
            justify-content: space-between;
            color: $corporativo;
            font-family: $title;
            svg {
                width: 12px;
            }
        }
        .buttons {
            display: flex;
            justify-content: flex-end;
            font-family: $title;
            .btn_evento {
                width: 30%;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $corporativo;
                border-color: $corporativo;
                svg {
                    width: 20px;
                    margin-right: 10px;
                }
                p {
                    margin-top: auto;
                    margin-bottom: auto;
                }
            }
            .btn_contrato {
                margin-right: 1%;
                svg {
                    margin-right: 10px;
                    fill: white;
                    width: 17px;
                }
            }
        }
    }
    @media screen and( max-width: $media-breackpoint-up-sm) {
        .row {
            .buttons {
                display: flex;
                justify-content: flex-end;
                flex-direction: column;
                font-family: $title;
                .btn_evento {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: $corporativo;
                    border-color: $corporativo;
                    svg {
                        width: 20px;
                        margin-right: 10px;
                    }
                    p {
                        margin-top: auto;
                        margin-bottom: auto;
                    }
                }
                .btn_contrato {
                    margin-right: 1%;
                    svg {
                        margin-right: 10px;
                        fill: white;
                        width: 17px;
                    }
                }
            }
        }
    }
}