@import '../../scss/index.scss';
.fade {
    width: 100%;
    height: 100%;
    .modal-dialog {
        width: 100%;
        .modal-content {
            background-color: black;
            color: white;
            border: 1px solid $corporativo;
            .modal-header {
                border-color: $corporativo;
                .modal-title {
                    font-family: $title;
                    color: $corporativo;
                }
                .btn-close {
                    background-color: $corporativo;
                    border-color: none;
                    transition: 0.3s ease-in;
                    &:hover {
                        color: $corporativo;
                        border-color: none;
                        filter: brightness(110%);
                    }
                    &:active,
                    &::before {
                        border-color: none;
                    }
                }
            }
        }
    }
}