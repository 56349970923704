@import '../../scss/index.scss';
.sidebar {
    position: fixed;
    height: 100%;
    width: 200px;
    left: 0;
    top: 0;
    padding: 80px 0 20px 0;
    color: white;
    background-color: #752a95 !important;
    box-shadow: 2px 0 5px rgba(117, 42, 149, 1) !important;
    transition: 0.3s ease-in;
    .sidebar__menu {
        text-decoration: none;
        display: flex;
        align-items: center;
        padding: 15px 25px;
        width: 100%;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        color: white;
        fill: white;
        svg {
            width: 25px;
            height: auto;
            margin-right: 20px;
        }
        p {
            margin-top: auto;
            margin-bottom: auto;
            font-family: $gotica;
            font-family: 20px !important;
        }
        &:hover {
            background-color: blueviolet;
            color: white;
            fill: white;
        }
    }
    .active {
        text-decoration: none;
        display: flex;
        align-items: center;
        border: 1px solid white !important;
        color: white !important;
        background-color: blueviolet !important;
        padding: 15px 25px;
        width: 100%;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        color: white;
        fill: white;
        svg {
            width: 25px;
            height: auto;
            margin-right: 20px;
        }
        p {
            margin-top: auto;
            margin-bottom: auto;
            font-family: $gotica;
            font-family: 12px !important;
        }
        &:hover {
            background-color: blueviolet;
            color: white;
            fill: white;
        }
    }
    .sidebar__active {
        svg {
            color: white;
            fill: white;
        }
        p {
            color: white !important;
        }
        background-color: blueviolet;
    }
}

.sidebar_reducer {
    position: fixed;
    height: 100%;
    width: 70px;
    left: 0;
    top: 0;
    padding: 80px 0 20px 0;
    color: white;
    background-color: #752a95;
    box-shadow: 2px 0 5px rgba(117, 42, 149, 1) !important;
    transition: 0.3s ease-in;
    overflow-y: auto;
    .sidebar__menu {
        text-decoration: none;
        display: flex;
        align-items: center !important;
        justify-content: center !important;
        flex-direction: column;
        padding: 10px 10px;
        width: 100%;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        color: white;
        fill: white;
        svg {
            width: 15px;
            height: auto;
            margin-top: auto;
            margin-bottom: auto;
        }
        p {
            margin-top: auto;
            margin-bottom: auto;
            color: white;
            font-size: 10px;
            font-weight: bold;
        }
        &:hover {
            background-color: blueviolet;
            color: white;
            fill: white;
            p {
                color: white;
            }
        }
    }
    .sidebar__active {
        svg {
            color: white;
            fill: white;
        }
        p {
            color: white !important;
        }
        background-color: blueviolet;
    }
}


/* #admin__icon {
    width: 70px;
    height: 70px;
} */