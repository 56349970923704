@import '../../scss/index.scss';
.contrato_container {
    font-family: $gotica;
    color: black !important;
    .row {
        h3 {
            font-family: $title;
            color: $corporativo;
        }
        h6,
        h5,
        p {
            color: black !important;
            span {
                font-weight: bold;
            }
        }
        .col-xl-3,
        .col-md-3,
        .col-sm-3 {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            img {
                margin-top: -10px;
                width: 80%;
            }
        }
        .col-xl-9,
        .col-md-9,
        .col-sm-9 {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .admindatos {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
        }
        /*  .comentarios_data {
            border-radius: 5px;
            padding-top: 5px;
            padding-bottom: 5px;
            border: 1px solid black;
        } */
    }
    .row_firma_usuario {
        padding-top: 50px;
        .firma_usuario {
            border-radius: 5px;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            border: 1px solid black;
            padding-top: 100px;
        }
    }
}