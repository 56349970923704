@import '../../../scss/index.scss';
.galeria_container {
    background: black;
    padding-top: 20px;
    padding-bottom: 100px;
    h1 {
        text-align: center;
        color: $corporativo;
        font-family: $title;
        padding-bottom: 20px;
    }
    .gallery {
        column-count: 3;
        column-width: 33%;
        padding: 0 12px;
        .card_image {
            position: relative;
            overflow: hidden;
            margin-bottom: 10px;
            transition: all 350ms ease;
            cursor: pointer;
            img {
                width: 100%;
                border: 1px solid #752A95;
                transition: filter 0.3s ease-in-out;
                filter: brightness(120%) !important;
            }
            .content {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: radial-gradient(circle, transparent, rgba(0, 0, 0, 0.1));
                z-index: 2;
                display: flex;
                justify-content: center;
                align-items: center;
                P {
                    color: $corporativo;
                    font-family: $title;
                    font-size: 18px;
                    padding-left: 5%;
                    padding-right: 5%;
                    text-shadow: 0 0 4px #752A95, 0 0 20px #752A95, 0 0 30px #752A95;
                    filter: brightness(170%);
                    text-align: center;
                    text-transform: uppercase;
                }
            }
            &:hover {
                filter: brightness(110%);
            }
        }
    }
    .model {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 80px;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: black;
        visibility: hidden;
        transition: opacity .4s ease, visibility .4s ease, transform .5s ease-in-out;
        opacity: 0;
        transform: scale(0);
        overflow: hidden;
        z-index: 999;
        img {
            width: 90%;
            height: auto;
            display: block;
            line-height: 0;
            box-sizing: border-box;
            padding: 0px 0 30px;
            margin: 0 auto;
        }
        svg {
            position: fixed;
            top: 10px;
            right: 10px;
            width: 2rem;
            height: auto;
            padding: 5px;
            color: $corporativo;
            cursor: pointer;
        }
    }
    .model.open {
        visibility: visible;
        opacity: 1;
        transform: scale(1);
    }
    @media screen and( max-width: $media-breackpoint-up-lg) {
        .gallery {
            column-count: 2;
            column-width: 50%;
            .pics {
                img {
                    padding: 5px;
                }
            }
        }
    }
}