@import '../../../scss/index.scss';
.container_sucursales {
    background-image: radial-gradient(circle, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.9)), url('../../../assets/jpg/image_horizontal2.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    padding-top: 50px;
    padding-bottom: 50px;
    align-items: center;
    justify-content: center;
    display: flex;
    .container {
        padding-top: 50px;
        padding-bottom: 50px;
        .texto {
            display: flex;
            justify-content: center;
            align-items: center;
            h1 {
                padding-top: 100px;
                padding-bottom: 100px;
                color: white;
                font-family: $title;
                text-decoration: none;
                text-shadow: 0 0 10px #69C528, 0 0 20px #69C528, 0 0 30px #69C528;
            }
        }
        .row {
            .col-xl-6,
            .col-md-6,
            .col-sm-12 {
                align-items: center;
                justify-content: center;
                display: flex;
                .box {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    background-color: black;
                    border-radius: 10px;
                    width: 300px;
                    padding: 10px;
                    box-shadow: 0px 3px 8px 3px rgba($color: #752A95, $alpha: 1.0);
                    .form-select {
                        /*  width: 50% !important; */
                        margin-bottom: 15px;
                        width: 90%;
                    }
                    button {
                        margin-bottom: 10px;
                        background: $corporativo;
                        color: white;
                        border: #791eb6;
                        font-family: $title;
                        width: 150px;
                        border-radius: 2px;
                        &:hover {
                            filter: brightness(110%);
                        }
                    }
                    h2 {
                        text-align: center;
                        font-family: $title;
                        color: $corporativo;
                        padding: 5px;
                    }
                }
                img {
                    width: 60%;
                }
            }
        }
    }
    @media screen and( max-width: $media-breackpoint-up-md) {
        .container {
            .row {
                .col-xl-6,
                .col-md-6,
                .col-sm-12 {
                    .box {
                        width: 80%;
                    }
                    img {
                        width: 50%;
                    }
                }
                .datos_salones {
                    padding-top: 20px;
                }
            }
        }
    }
}