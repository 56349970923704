@import '../../../scss/index.scss';
.admin__container {
    padding-top: 80px;
    .admin_dasboard_nab {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-self: center;
        border-bottom: 1px solid $corporativo;
        .fecha_hoy {
            background-color: #E3E3F2;
            padding: 5px;
            border-radius: 5px;
            font-family: $title;
            color: $corporativo;
            margin-top: auto;
            margin-bottom: auto;
        }
        .Contaier_navBar_principal {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            height: 60px;
            svg {
                width: 30px;
                color: $corporativo;
            }
            .datauser {
                padding-right: 10px;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                flex-direction: column;
                font-family: $gotica;
                font-size: 12px;
                p {
                    margin-top: auto;
                    font-weight: bold;
                    color: $corporativo;
                }
                span {
                    margin-top: -20px;
                    color: $font-grey;
                }
            }
        }
    }
    .admin_dasboard_body {
        /*  padding-top: 80px; */
        padding-top: 20px;
        .row {
            .admindatos {
                .Card_datos {
                    background-color: $azul1;
                    padding: 10px;
                    margin-bottom: 10px;
                    border-radius: 10px;
                    /*  box-shadow: 0 0.2rem 1rem rgba(0, 150, 246, 1); */
                    color: $action;
                    .ganancias {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background-color: $action;
                        border-radius: 100%;
                        width: 50px;
                        height: 50px;
                        svg {
                            width: 30px;
                            fill: #81c9fa;
                        }
                    }
                    p {
                        margin-top: 5px;
                        font-family: $Subtitle;
                        font-weight: bold;
                        font-size: 13px;
                    }
                    h1 {
                        margin-top: -15px;
                        font-family: $title;
                        font-weight: bold;
                        font-size: 25px;
                    }
                }
                .Card_ganancias {
                    background-color: #5ccb5f;
                    padding: 10px;
                    margin-bottom: 10px;
                    border-radius: 10px;
                    /*  box-shadow: 0 0.2rem 1rem rgba(92, 203, 95, 1); */
                    color: #005d16;
                    .ganancias {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background-color: #005d16;
                        border-radius: 100%;
                        width: 50px;
                        height: 50px;
                        svg {
                            width: 30px;
                            fill: #5ccb5f;
                        }
                    }
                    p {
                        margin-top: 5px;
                        font-family: $Subtitle;
                        font-weight: bold;
                        font-size: 13px;
                    }
                    h1 {
                        margin-top: -15px;
                        font-family: $title;
                        font-weight: bold;
                        font-size: 25px;
                    }
                }
                .Card_users {
                    background-color: #bc4ed8;
                    padding: 10px;
                    margin-bottom: 10px;
                    border-radius: 10px;
                    box-shadow: 0 1rem 2rem rgba(0, 0, 0, .3);
                    color: #752A95;
                    .ganancias {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background-color: #752A95;
                        border-radius: 100%;
                        width: 50px;
                        height: 50px;
                        svg {
                            width: 30px;
                            fill: #bc4ed8;
                        }
                    }
                    p {
                        margin-top: 5px;
                        font-family: $Subtitle;
                        font-weight: bold;
                        font-size: 13px;
                    }
                    h1 {
                        margin-top: -15px;
                        font-family: $title;
                        font-weight: bold;
                        font-size: 25px;
                    }
                }
                .Card_admins {
                    background-color: #752A95;
                    padding: 10px;
                    margin-bottom: 10px;
                    border-radius: 10px;
                    box-shadow: 0 1rem 2rem rgba(0, 0, 0, .3);
                    color: $corporativo;
                    .ganancias {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background-color: $corporativo;
                        border-radius: 100%;
                        width: 50px;
                        height: 50px;
                    }
                    p {
                        margin-top: 5px;
                        font-family: $Subtitle;
                        font-weight: bold;
                        font-size: 13px;
                    }
                    h5 {
                        font-family: $gotica;
                        font-weight: bold;
                        color: white;
                    }
                    .table-responsive {
                        thead {
                            tr {
                                th {
                                    font-family: $gotica;
                                    color: white;
                                }
                            }
                        }
                        tbody {
                            tr {
                                font-family: $Subtitle;
                                color: white;
                            }
                            .admin_info_eventos {
                                font-weight: bold;
                                span {
                                    color: red;
                                }
                            }
                        }
                    }
                }
            }
            .eventos_card {
                background-color: #752A95;
                padding: 10px;
                margin-bottom: 10px;
                border-radius: 10px;
                box-shadow: 0 1rem 2rem rgba(0, 0, 0, .3);
                color: white;
                h5 {
                    font-family: $gotica;
                    font-weight: bold;
                }
                .table-responsive {
                    color: white;
                    thead {
                        tr {
                            th {
                                font-family: $gotica;
                                color: white;
                            }
                        }
                    }
                    tbody {
                        tr {
                            font-family: $Subtitle;
                            color: white;
                            td {
                                span.adeudo {
                                    color: $rojo;
                                }
                            }
                        }
                        .opciones {
                            cursor: pointer;
                            justify-content: center;
                            align-items: center;
                            display: flex;
                            flex-direction: column;
                            margin-right: 15px;
                            svg {
                                width: 25px;
                                fill: $action;
                            }
                            p {
                                font-size: 10px;
                                color: $action;
                            }
                        }
                    }
                }
            }
        }
    }
}