@import '../../scss/index.scss';
.navbar_top_user {
    position: fixed;
    z-index: 1000;
    background: black;
    display: flex;
    flex-direction: column;
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .1);
    height: 95px !important;
    .line {
        width: 100%;
        height: 3px;
        background: radial-gradient(circle, #69C528, transparent);
        filter: brightness(150%);
    }
    .container {
        padding-top: 10px;
        padding-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        .navBar {
            a {
                font-family: $title;
                font-size: 1.7 rem;
                color: var(--black);
                margin: 0 1rem;
                text-decoration: none;
                transition: 0.3s ease-in;
                &:hover {
                    color: blueviolet;
                    text-decoration: none;
                }
            }
        }
        .icons {
            .cuenta {
                background-color: $corporativo !important;
                border-radius: 3px;
                padding: 5px;
                border: 1px solid $corporativo;
                cursor: pointer;
                color: white;
                &:hover {
                    color: white;
                    text-decoration: none;
                    text-shadow: 0 0 10px #69C528, 0 0 20px #69C528, 0 0 30px #69C528;
                    /* Efecto de sombra de texto */
                    filter: brightness(110%);
                }
            }
            a {
                font-family: $title;
                font-size: 1.7 rem;
                color: var(--black);
                margin: 0 1rem;
                text-decoration: none;
                transition: 0.3s ease-in;
                &:hover {
                    color: blueviolet;
                    text-decoration: none;
                }
            }
            svg {
                margin-left: 1rem;
                width: 25px;
                height: 25px;
                transition: 0.3s ease-in;
                &:hover {
                    color: blueviolet;
                    fill: blueviolet;
                }
            }
            .user {
                cursor: pointer;
            }
            .carro {
                cursor: pointer;
            }
            .opciones_user {
                display: none;
                padding: 5px;
                margin-top: 10px;
                background-color: white;
                position: absolute;
                transition: .3s ease-in;
                li {
                    list-style: none;
                    a {
                        cursor: pointer;
                    }
                }
            }
            .mostrar {
                display: block;
                padding: 5px;
                margin-top: 10px;
                background-color: white;
                position: absolute;
                li {
                    list-style: none;
                    a {
                        cursor: pointer;
                    }
                }
            }
        }
        .menu-bar {
            display: none;
            cursor: pointer;
            svg {
                width: 25px;
                height: 25px;
            }
        }
        .logo {
            img {
                width: 200px !important;
            }
        }
    }
    @media screen and( max-width: $media-breackpoint-up-lg) {
        .container {
            .menu-bar {
                display: block;
            }
            .logo {
                margin-left: 1rem;
            }
            .navBar {
                opacity: 0%;
                position: absolute;
                top: 100%;
                left: -120%;
                width: 100%;
                background: #eee;
                border-top: 1rem solid rgba(0, 0, 0, .1);
                transition: 0.5s ease-in;
                a {
                    font-size: 2rem;
                    background: white;
                    padding: 1.5 rem;
                    margin: 1.5 rem;
                    display: block;
                    text-align: center;
                }
            }
            .nav__active {
                transform: translateX(0);
                transition: 0.5s ease-in-out;
                opacity: 100%;
                left: 0%;
            }
        }
    }
}