@import "../../../scss/index.scss";

.nosotros_doggy {
  
  background-image: radial-gradient(
    circle,
    rgba(48, 160, 225, 0.2), /* #30A0E1 con 20% de opacidad */
    rgba(48, 160, 225, 1)    /* #30A0E1 con 100% de opacidad */
), url("../../../assets/jpg/fondo.jpg");
  .container {
    h3 {
      text-shadow: 1px 12px 20px black;
      --tw-text-opacity: 1;
      color: #30A0E1;
      font-style: italic;
      font-weight: 900;
      font-size: 3.25rem;
      line-height: 3.5rem;
      text-align: center;
      padding-top: 2.5rem;
      font-family: $titleDoggy;
    }
    .row {
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
      .col-xl-5,
      .col-md-12,
      .col-sm-12,
      .col-12 {
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        p {
            text-shadow: 1px 12px 20px black;
            color: white;
          font-size: 1.65rem;
          line-height: 1.75rem;
          text-align: center;
          margin: 0;
          font-family: $subTitleDoggy;
        }
        .img_nosotros{
            padding-top: 2.5rem; 
            padding-bottom: 2.5rem;
            width: 95%;
            animation: meneo 5s infinite;
        }
      }
    }
  }
}

@keyframes meneo {
    0%, 100% {
        transform: translateY(0); /* Posición inicial y final */
    }
    25% {
        transform: translateY(-10px); /* Sube 10px */
    }
    50% {
        transform: translateY(10px); /* Baja 10px */
    }
    75% {
        transform: translateY(-10px); /* Sube 10px */
    }
}
