@import "../../../scss/index.scss";
.doggyDes {
  background-color: #552676;
  /* background-image: radial-gradient(
    circle,
    rgba(85, 38, 118, 0.2),
    rgba(85, 38, 118, 1)   
  ), 
  url("../../../assets/jpg/inflableFondo.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover; */
  .container {
    h3 {
      text-shadow: 1px 12px 20px black;
      --tw-text-opacity: 1;
      color: #30a0e1;
      font-style: italic;
      font-weight: 900;
      font-size: 3.25rem;
      line-height: 3.5rem;
      text-align: center;
      padding-top: 4.5rem;
      font-family: $titleDoggy;
      transform: rotate(-5deg);
    }
    .subtitulo {
      font-size: 1.125rem;
      line-height: 1.75rem;
      text-align: center;
      color: white;
      font-family: $gotica;
      transform: rotate(-5deg);
      padding-bottom: 2.5rem;
    }
    .row {
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
      .col-xl-5,
      .col-md-12,
      .col-sm-12,
      .col-12 {
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        p {
            text-shadow: 1px 12px 20px black;
            color: white;
          font-size: 1.75rem;
          line-height: 1.75rem;
          text-align: center;
          margin: 0;
          font-family: $subTitleDoggy;
        }
        img {
            padding-top: 3.5rem;
            width: 43%;
            position: relative; /* Asegura que la animación se mantenga en su posición */
            animation: silbido 2s infinite ease-in-out;
            transform-origin: bottom center;
          }
     
      }
    }
  }
}


@keyframes silbido {
    0% {
      transform: rotate(0deg) scale(1);
    }
    25% {
      transform: rotate(-1deg) scale(0.99); /* Se encoge un poco */
    }
    50% {
      transform: rotate(0deg) scale(1); /* Crece un poco */
    }
    75% {
      transform: rotate(-1deg) scale(0.99); /* Se encoge nuevamente */
    }
    100% {
      transform: rotate(0deg) scale(1);
    }
  }