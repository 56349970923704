@import '../../scss/index.scss';
.footer_web {
    background-color: black;
    .container {
        padding-top: 10px;
        .row {
            .footer_info {
                img {
                    width: 150px;
                    height: auto;
                }
                .row {
                    .contenido {
                        margin-top: 10px;
                        h5 {
                            color: white;
                            font-family: $title;
                            border-bottom: 3px solid #69C528;
                            ;
                            width: 75px;
                            transition: 0.3s ease-out;
                            &:hover {
                                width: 100px;
                            }
                        }
                        p {
                            font-family: $gotica;
                            color: white;
                            font-size: 12px;
                        }
                        .data {
                            display: flex;
                            align-items: center;
                            svg {
                                width: 18px;
                                margin-right: 10px;
                                padding-top: 10px;
                                color: #69C528;
                            }
                            p {
                                padding-top: 10px;
                                margin-top: auto;
                                margin-bottom: auto;
                                font-size: 14px;
                                font-family: $gotica;
                            }
                        }
                        .email_form {
                            .form-control {
                                margin-bottom: 10px;
                                font-family: $gotica;
                                color: $corporativo;
                                border-color: $corporativo;
                            }
                            button {
                                width: 100%;
                                height: 40px;
                                border-radius: 4px;
                                background-color: #69C528;
                                border-color: #69C528;
                                color: white;
                                font-family: $title;
                                margin-bottom: 10px;
                                font-size: 20px;
                            }
                        }
                    }
                    .redes_sociales {
                        align-items: center;
                        display: flex;
                        margin-bottom: 10px;
                        .content {
                            text-decoration: none;
                            cursor: pointer;
                            svg {
                                padding: 10px;
                                width: 40px;
                                height: auto;
                                margin-right: 15px;
                                color: white;
                                transition: 0.2s ease-in-out;
                                &:hover {
                                    background-color: #752A95;
                                }
                            }
                        }
                    }
                    .direccion {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        svg {
                            width: 30px !important;
                            margin-right: 10px;
                            padding-top: 10px;
                            color: #69C528;
                        }
                        p {
                            padding-top: 10px;
                            margin-top: auto;
                            margin-bottom: auto;
                            font-size: 12px;
                            color: white;
                            font-family: $gotica;
                        }
                    }
                }
            }
            .footer_bottom {
                font-size: 20px;
                color: white;
                font-family: $title;
                text-align: center;
                border-top: 3px solid #69C528;
                p {
                    margin-top: 10px;
                    margin-bottom: 10px;
                }
                
            }
        }
    }
}