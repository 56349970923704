@import '../../../scss/index.scss';
.users_section {
    padding-top: 80px;
    .users_section_nav {
        display: flex;
        align-items: center;
        width: 100%;
        height: 50px;
        background-color: #752A95;
        .container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            h5 {
                margin-top: auto;
                margin-bottom: auto;
                color: white;
                font-family: $title;
                margin-right: 20px;
            }
            .search_bar {
                input {
                    width: 100%;
                }
            }
        }
    }
    .users_section_data {
        .table {
            color: white;
            tbody {
                tr {
                    .options_user_name {
                        white-space: nowrap;
                        /* Evita el salto de línea */
                        overflow: hidden;
                        /* Oculta el texto que no cabe */
                        text-overflow: ellipsis;
                        /* Agrega puntos suspensivos (...) cuando el texto se recorta */
                        max-width: 15ch;
                    }
                }
            }
            tr {
                font-family: $gotica;
                .options_user_admin {
                    display: flex;
                    align-items: center;
                    height: 100%;
                    svg {
                        margin-top: auto;
                        margin-bottom: auto;
                        width: 20px;
                        color: #f8f8fb;
                        cursor: pointer;
                    }
                    .edit {
                        justify-content: center;
                        align-items: center;
                        display: flex;
                        flex-direction: column;
                        margin-right: 10px;
                        svg {
                            width: 25px;
                            color: $action;
                        }
                        p {
                            font-size: 10px;
                            color: $action;
                        }
                    }
                    .delete {
                        justify-content: center;
                        align-items: center;
                        display: flex;
                        flex-direction: column;
                        margin-right: 10px;
                        svg {
                            color: $danger;
                        }
                        p {
                            font-size: 10px;
                            color: $danger;
                        }
                    }
                    .profile {
                        justify-content: center;
                        align-items: center;
                        display: flex;
                        flex-direction: column;
                        margin-right: 10px;
                        a {
                            text-decoration: none;
                        }
                        svg {
                            fill: $success;
                        }
                        p {
                            font-size: 10px;
                            color: $success;
                        }
                    }
                    .evento {
                        justify-content: center;
                        align-items: center;
                        display: flex;
                        flex-direction: column;
                        a {
                            text-decoration: none;
                        }
                        svg {
                            fill: $corporativo;
                        }
                        p {
                            font-size: 10px;
                            color: $corporativo;
                        }
                    }
                }
            }
        }
    }
}