@font-face {
    font-family: 'Neuropol';
    src: url('../assets/fonts/Neuropol.ttf') format('truetype');
}

@font-face {
    font-family: 'TcMiloRegular';
    src: url('../assets/fonts/TcMiloRegular-0vj5r.otf') format('truetype');
}

$font-light: #fff;
$font-grey: #a2a2a2;
$font-dark: #000;
$background-light: #fff;
$background-grey: #f0f2f5;
$background-grey-light: #fafafa;
$background-dark: #1f1f1f;
$background-success: #84284c1f;
$background-error: #ff00001f;
$border-grey: #484848;
$border-dark: #000;
$action: #0096f6;
$danger: #ed4956;
$success: #84b84c;
$happy: #ffc300;
$corporativo: #69c528;
$rojo: #ec3c52;
$naranja:#ff6900;
$amarillo:#fcb900;
$verde1: #00d084;
$verde2: #00d084;
$azul1: #8ed1fc;
$azul2: #0693e3;
$rojo1: #EB144C;
$rosa1: #F78DA7;
//Fuentes
$Subtitle:'Quicksand',
sans-serif;
$gotica:  "Poppins", serif;
$textos: 'Oswald',
sans-serif;
$titulos:'Questrial',
cursive;
$limonada:'Lemonada',
cursive;
$title:'Neuropol',
cursive;
$titleDoggy:'TcMiloRegular',
cursive;
$subTitleDoggy: "Bebas Neue", serif;
//Responsive up size
$media-breackpoint-up-xs: 390px;
$media-breackpoint-up-movile: 429px;
$media-breackpoint-up-sm: 576px;
$media-breackpoint-up-md: 686px;
$media-breackpoint-up-md-bootstrap: 767px;
$media-breackpoint-up-lg: 992px;
$media-breackpoint-up-xl: 1193px;