@import '../../scss/index.scss';
#naranja {
    width: 100%;
    height: 20px;
    background-color: $naranja;
    &:checked,
    &:focus {
        background-color: $naranja !important;
        background: $naranja !important;
    }
}

#amarillo {
    width: 100%;
    height: 20px;
    background-color: $amarillo;
}

#verde1 {
    width: 100%;
    height: 20px;
    background-color: $verde1;
}

#verde2 {
    width: 100%;
    height: 20px;
    background-color: $corporativo;
}

#azul1 {
    width: 100%;
    height: 20px;
    background-color: $azul1;
}

#azul2 {
    width: 100%;
    height: 20px;
    background-color: $azul2;
}

#rojo {
    width: 100%;
    height: 20px;
    background-color: $rojo1;
}

#rosa {
    width: 100%;
    height: 20px;
    background-color: $rosa1;
}

#morado {
    width: 100%;
    height: 20px;
    background-color: #7f00b2;
    color: white;
}

#blanco {
    width: 100%;
    height: 20px;
    background-color: $font-light;
}

#negro {
    width: 100%;
    height: 20px;
    background-color: $font-dark;
    color: white;
}

#gris {
    width: 100%;
    height: 20px;
    background-color: $background-grey;
}

#gris2 {
    width: 100%;
    height: 20px;
    background-color: $font-grey;
}


/* select option:checked,
select option:focus,
select option:active,
select option:hover {
    background: transparent !important;
    background-color: transparent !important;
} */