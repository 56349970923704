@import '../../../scss/index.scss';
.register-form {
    a {
        margin-left: 2px;
        text-decoration: none;
        color: white;
        transition: 0.1s ease-in;
        font-family: $gotica;
        &:hover {
            color: white;
            font-weight: bold;
        }
    }
}