@import '../../scss/index.scss';
.seccion_paquete_container {
    padding-top: 80px;
    padding-bottom: 20px;
    background-image: radial-gradient(circle, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.9)), url('../../assets/jpg/image_horizontal2.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    .row {
        h1 {
            font-family: $title;
            color: $corporativo;
        }
        padding-top: 20px;
        .col-xl-12,
        .col-md-12,
        .col-sm-12 {
            h1 {
                font-family: $title;
                text-align: center;
            }
            p {
                margin-bottom: 5px;
                margin-top: 5px;
                font-family: $gotica;
                font-weight: bold;
                text-align: justify;
                color: white;
            }
            h5 {
                color: white;
                font-family: $title;
                span {
                    font-weight: bold;
                }
            }
            .titulo_paquete {
                text-align: start;
            }
            .list_descripcion {
                display: flex;
                justify-content: flex-start !important;
                align-items: center;
                width: 90%;
                svg {
                    width: 20px;
                    margin-right: 5px;
                    margin-bottom: 5px;
                    margin-top: 5px;
                    color: $corporativo;
                }
                p {
                    margin-bottom: 5px;
                    margin-top: 5px;
                    font-family: $gotica;
                    font-weight: bold;
                }
            }
        }
        .container_image {
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                margin-top: auto;
                margin-bottom: auto;
                width: 100%;
            }
        }
        .card_image {
            position: relative;
            overflow: hidden;
            margin-bottom: 10px;
            img {
                width: 100%;
                border: 1px solid #752A95;
                transition: filter 0.3s ease-in-out;
                filter: brightness(120%) !important;
            }
            .content {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 2;
            }
        }
    }
}