@import '../../../scss/index.scss';

.footer_doggy{
    font-family: $gotica;
    .container{
      .row_footer {
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
      .col-xl-6,
      .col-md-6,
      .col-sm-12,
      .col-12 {
        .logoFooter{
            width: 200px;
            animation: meneo 5s infinite;
            animation: none;
        }
       
        .svg-text-container {
            padding-top: 20px;
            display: flex;
            align-items: start;
            gap: 8px;
          
          
          svg {
            width: 25px;
            height: 25px;
            fill: black !important;
          }
          
          .text {
            font-size: 16px;
            color: black;
            font-weight: 600;
            a{
                color: black;
              }
          }
        }
          
      }
      .redes{
        svg{
          color: black !important;
            fill: black !important;
            width: 50px !important;
            height: 50px !important;
            transition: transform 0.2s ease-in-out, color 0.2s ease-in-out;
            &:hover {
              transform: scale(1.1);
              fill: #2a66ff !important;
            }
            margin-bottom: 2.5rem;
            margin-top: 1.5rem;
        }
    }
    }  
    }
    
}

