@import '../../../scss/index.scss';
.conocenos {
    padding-bottom: 50px;
    h1 {
        text-align: center;
        margin: 0;
        padding: 40px 20px;
        font-family: $title;
        font-size: 5rem;
        text-shadow: 0 5px 15px rgba(0, 0, 0, .3);
        color: $corporativo;
        span {
            color: #752A95;
        }
    }
    p {
        color: white;
        font-family: $gotica;
        text-align: center;
        font-size: 20px;
    }
    @media screen and( max-width: $media-breackpoint-up-sm) {
        h1 {
            padding: 20px 10px;
            font-size: 4rem;
        }
        p {
            padding-left: 10%;
            padding-right: 10%;
            font-size: 15px;
        }
    }
    @media screen and( max-width: $media-breackpoint-up-movile) {
        h1 {
            padding: 20px 10px;
            font-size: 2rem;
        }
    }
}