@import '../../../scss/index.scss';
.container_packages_page {
    padding-top: 40px;
    padding-bottom: 40px;
    .row {
        h1 {
            text-align: center;
            font-family: $title;
            color: $corporativo;
            padding-bottom: 20px;
        }
        .container_paquetes_body {
            margin-top: 10px;
            margin-bottom: 20px;
            .container {
                background: whitesmoke;
                color: $corporativo;
                border-radius: 10px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding-bottom: 20px;
                box-shadow: 0 .3rem 1rem rgba(255, 255, 255, 1) !important;
                h4 {
                    text-align: center;
                    margin: auto;
                    margin-top: 10px;
                    font-family: $title;
                    text-transform: uppercase;
                }
                hr {
                    border: 1px solid;
                    width: 90%;
                }
                a {
                    text-decoration: none;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-top: 10px;
                    font-family: $title;
                    padding-top: 5px;
                    padding-bottom: 5px;
                    color: $corporativo;
                    width: 90%;
                    border-radius: 5px;
                    border-color: $corporativo;
                    background-color: white;
                    svg {
                        margin-left: 15px;
                        width: 15px;
                        color: $corporativo;
                        fill: $corporativo;
                    }
                }
            }
            .list_descripcion {
                display: flex;
                justify-content: flex-start !important;
                align-items: center;
                width: 90%;
                svg {
                    width: 20px;
                    margin-right: 5px;
                    margin-bottom: 5px;
                    margin-top: 5px;
                }
                p {
                    margin-bottom: 5px;
                    margin-top: 5px;
                    font-family: $gotica;
                    font-size: 13px;
                    font-weight: bold;
                }
            }
        }
    }
    .noPaquete {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 80vh;
        background-image: radial-gradient(circle, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 1)), url("../../../assets/jpg/banner_no_paquete.jpg");
        background-size: cover;
        background-position: center;
        h1 {
            padding-left: 5%;
            padding-right: 5%;
            font-family: $title;
            color: white;
            text-align: center;
            text-shadow: 0 0 10px #752A95, 0 0 20px #752A95, 0 0 30px #752A95;
        }
    }
}