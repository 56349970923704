@import '../../scss/index.scss';
.modal__normal {
    text-align: center;
    width: 100%;
    height: 100%;
    .modal-dialog {
        width: 100%;
    }
    .modal-content {
        background-color: black;
        border: 1px solid $corporativo;
        color: white;
        .modal-title {
            color: $corporativo;
            font-family: $gotica;
        }
        .btn-close {
            background-color: $corporativo;
            border-color: none;
            transition: 0.3s ease-in;
            &:hover {
                color: $corporativo;
                border-color: none;
                filter: brightness(110%);
            }
            &:active,
            &::before {
                border-color: none;
            }
        }
        .modal-body {
            h3 {
                text-align: start;
                font-family: $gotica;
                color: $corporativo;
            }
            form {
                display: flex;
                flex-direction: column;
                text-align: center !important;
                .mb-3 {
                    .form-control {
                        height: 50px;
                        font-family: $gotica;
                    }
                    select {
                        height: 50px;
                        font-family: $gotica;
                    }
                    .form-check-input {
                        height: 30px;
                        width: 70px;
                    }
                }
                button {
                    height: 50px;
                    background-color: $corporativo;
                    border-color: $corporativo;
                    font-family: $gotica;
                    font-weight: bold;
                    .spinner-border {
                        margin-right: 10px;
                    }
                }
                .opciones {
                    margin-top: 10px;
                    display: flex;
                    justify-content: center;
                    text-align: center;
                    p {
                        font-family: $gotica;
                        cursor: pointer;
                    }
                    .cambio {
                        color: $corporativo;
                        font-weight: bold;
                    }
                }
            }
        }
    }
}