@import '../../scss/index.scss';
.navbar_top_location {
    position: fixed;
    z-index: 1000;
    background: black;
    display: flex;
    flex-direction: column;
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .1);
    height: 86px;
    /*    justify-content: space-between;
    align-content: center; */
    .line {
        width: 100%;
        height: 3px;
        background: radial-gradient(circle, #69C528, transparent);
        filter: brightness(150%);
    }
    .container {
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .navBar {
            a {
                cursor: pointer;
                font-family: $gotica;
                font-size: 1.4 rem;
                color: white !important;
                margin: 0 1rem;
                text-decoration: none;
                transition: 0.3s ease-in; 
                font-weight: bold;
                &:hover {
                    color: #69C528 !important;
                    text-decoration: none;
                    text-shadow: 0 0 10px #69C528, 0 0 20px #69C528, 0 0 30px #69C528;
                    /* Efecto de sombra de texto */
                    filter: brightness(150%);
                    /* Aumento de brillo */
                }
            }
        }
        .icons {
            .cuenta {
                background-color: $corporativo;
                border-radius: 3px;
                padding: 5px;
                border: 1px solid $corporativo;
                color: white;
                cursor: pointer;
                &:hover .opciones_user {
                    display: block;
                    padding: 5px;
                    margin-top: 10px;
                    background-color: white;
                    position: absolute;
                    li {
                        list-style: none;
                    }
                }
            }
            a {
                font-family: $title;
                font-size: 1.7 rem;
                color: var(--black);
                /* margin: 0 1rem; */
                text-decoration: none;
                transition: 0.3s ease-in;
                &:hover {
                    text-decoration: none;
                    text-shadow: 0 0 10px #69C528, 0 0 20px #69C528, 0 0 30px #69C528;
                    /* Efecto de sombra de texto */
                    filter: brightness(120%);
                }
            }
            svg {
                margin-left: 1rem;
                width: 25px;
                height: 25px;
                transition: 0.3s ease-in;
                &:hover {
                    color: blueviolet;
                    fill: blueviolet;
                }
            }
            .user {
                cursor: pointer;
            }
            .carro {
                cursor: pointer;
            }
            .opciones_user {
                z-index: 10;
                border: 1px solid $corporativo;
                display: none;
                padding: 5px;
                margin-top: 10px;
                background-color: black !important;
                position: absolute;
                transition: .3s ease-in;
                li {
                    list-style: none;
                    a {
                        cursor: pointer;
                        color: $corporativo;
                    }
                }
            }
            .mostrar {
                display: block;
                padding: 5px;
                margin-top: 10px;
                background-color: white;
                position: absolute;
                li {
                    list-style: none;
                    a {
                        cursor: pointer;
                    }
                }
            }
        }
        .menu-bar {
            display: none;
            cursor: pointer;
            svg {
                width: 25px;
                height: 25px;
                color: $corporativo;
            }
        }
        .logo {
            img {
                width: 200px !important;
            }
        }
    }
    @media screen and( max-width: $media-breackpoint-up-lg) {
        .container {
            .menu-bar {
                display: block;
            }
            .logo {
                margin-left: 1rem;
            }
            .navBar {
                opacity: 0%;
                position: absolute;
                top: 100%;
                left: -120%;
                width: 40% !important;
                height: 100vh;
                background: black !important;
                border-top: 1rem solid rgba(0, 0, 0, .1);
                transition: 0.5s ease-in;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                a {
                    font-size: 18px !important;
                    background: black !important;
                    display: block;
                    text-align: start !important;
                    font-family: $gotica !important;
                    padding-top: 15px;
                    padding-bottom: 15px;
                    font-weight: lighter !important;
                }
            }
            .nav__active {
                transform: translateX(0);
                transition: 0.5s ease-in-out;
                opacity: 100%;
                left: 0%;
            }
        }
    }
    @media screen and( max-width: $media-breackpoint-up-sm) {
        .container {
            .logo {
                margin-top: 5px;
                margin-left: 0px;
                img {
                    width: 120px !important;
                }
            }
            .icons {
                .opciones_user {
                    font-size: 12px;
                }
            }
        }
    }
    @media screen and( max-width: $media-breackpoint-up-xs) {
        height: 50px;
        .line {
            margin-top: 5px;
        }
        .container {
            padding: 3px 5%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .navBar {
                width: 55% !important;
                a {
                    font-family: $title;
                    font-size: 1.7 rem;
                    color: var(--black);
                    margin: 0 1rem;
                    text-decoration: none;
                    transition: 0.3s ease-in;
                    &:hover {
                        color: blueviolet;
                        text-decoration: none;
                    }
                }
            }
            .icons {
                .cuenta {
                    border-radius: 3px;
                    font-size: 12px;
                    padding: 2px;
                }
                svg {
                    margin-left: 0.5rem !important;
                    width: 20px !important;
                    height: 20px !important;
                }
            }
        }
    }
}