@import '../../../scss/index.scss';
.banner__container_locate {
    padding-top: 130px !important;
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .form_container_cotizador {
        margin-top: -80px !important;
        z-index: 2;
        border-radius: 10px;
        padding: 15px;
        background-color: black;
        color: $corporativo;
        font-family: $gotica;
        box-shadow: 0px 3px 8px 3px rgba($color: #752A95, $alpha: 1.0);
        width: 90%;
        h3 {
            font-family: $title;
            text-align: center;
        }
        form {
            input {
                color: $corporativo;
                font-weight: bold;
            }
            button {
                width: 100%;
                background-color: $corporativo;
                border: $corporativo;
                &:hover {
                    background-color: $corporativo;
                    border: $corporativo;
                }
            }
        }
    }
}