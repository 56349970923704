@import '../../scss/index.scss';
.container_active {
    height: 80vh;
    padding-top: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    img {
        width: 150px;
        height: auto;
    }
    .box {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        h7 {
            margin-top: 20px;
            font-family: $title;
            color: white;
            text-shadow: 1px 1px 4px rgba(105, 197, 40, 1);
        }
        .btn {
            margin-top: 20px;
            width: 300px;
            background-color: $corporativo;
            border-color: $corporativo;
        }
    }
}