@import '../../../scss/index.scss';
.Select_paquete_data_admin {
    .spnner_container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .spinner-grow {
            background-color: $corporativo;
            margin-right: 10px;
        }
        span {
            color: $corporativo;
            font-size: 22px;
        }
    }
}

.container_paquetes_body {
    .row {
        .col-xl-12,
        .col-md-12,
        .col-sm-12 {
            li {
                font-weight: bold;
            }
        }
    }
    .funciones_paquetes {
        display: flex;
        justify-content: space-between;
        align-items: center;
        svg {
            margin-top: auto;
            margin-bottom: auto;
            width: 20px;
        }
        h4 {
            color: $corporativo;
            font-family: $title;
            margin-top: auto;
            margin-bottom: auto;
        }
    }
    .form-label {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: bold;
        color: $corporativo;
        svg {
            margin-top: auto;
            margin-bottom: auto;
            width: 12px;
        }
    }
    h6 {
        color: $corporativo;
        font-weight: bold;
    }
}