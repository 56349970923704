@import '../../../scss/index.scss';
.eventos_user_container {
    padding-top: 80px;
    .users_section_nav {
        display: flex;
        align-items: center;
        width: 100%;
        height: 50px;
        background-color: #752A95;
        .container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            h5 {
                margin-top: auto;
                margin-bottom: auto;
                color: white;
                font-family: $title;
                margin-right: 20px;
            }
        }
    }
    .users_section_data {
        .table {
            color: white;
            tr {
                font-family: $gotica;
                .options_user_admin {
                    display: flex;
                    align-items: center;
                    height: 100%;
                    svg {
                        margin-top: auto;
                        margin-bottom: auto;
                        width: 20px;
                        color: #f8f8fb;
                        cursor: pointer;
                    }
                    .pay {
                        justify-content: center;
                        align-items: center;
                        display: flex;
                        flex-direction: column;
                        margin-right: 10px;
                        svg {
                            width: 25px;
                            fill: #2a593f;
                        }
                        p {
                            font-size: 10px;
                            color: #2a593f;
                        }
                    }
                    .pdf {
                        justify-content: center;
                        align-items: center;
                        display: flex;
                        flex-direction: column;
                        margin-right: 10px;
                        a {
                            text-decoration: none;
                        }
                        svg {
                            width: 22px;
                            fill: $corporativo;
                        }
                        p {
                            font-size: 10px;
                            color: $corporativo;
                        }
                    }
                    .delete {
                        justify-content: center;
                        align-items: center;
                        display: flex;
                        flex-direction: column;
                        svg {
                            color: $danger;
                        }
                        p {
                            font-size: 10px;
                            color: $danger;
                        }
                    }
                    .editar {
                        justify-content: center;
                        align-items: center;
                        display: flex;
                        flex-direction: column;
                        margin-right: 10px;
                        a {
                            text-decoration: none;
                        }
                        svg {
                            width: 25px;
                            color: $action;
                        }
                        p {
                            font-size: 10px;
                            color: $action;
                        }
                    }
                }
                td {
                    .abonos {
                        font-weight: bold;
                    }
                    .adeudo {
                        color: $rojo;
                    }
                }
                .total {
                    color: #2a593f;
                }
            }
        }
    }
}