@import "../../../scss/index.scss";

.contactoDoggy{
    background-color: #30a0e1;
    background-image: radial-gradient(
    circle,
    rgba(85, 38, 118, 0.2),
    rgba(85, 38, 118, 1)   
  ), 
  url("../../../assets/jpg/fondo2.jpeg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover; 
    .container{
        h3 {
            text-shadow: 1px 12px 20px black;
            --tw-text-opacity: 1;
            color: #30a0e1;
            font-style: italic;
            font-weight: 900;
            font-size: 3.25rem;
            line-height: 3.5rem;
            text-align: center;
            padding-top: 4.5rem;
            padding-bottom: 2.5rem;
            font-family: $titleDoggy;
            transform: rotate(-5deg);
          }
          .row {
            padding-top: 2.5rem;
            padding-bottom: 2.5rem;
          .col-xl-5,
          .col-md-12,
          .col-sm-12,
          .col-12 {
            p {
                text-shadow: 1px 12px 20px black;
                color: white;
              font-size: 2rem;
              line-height: 2rem;
              text-align: center;
              margin: 0;
              font-family: $subTitleDoggy;
            }
            img {
                padding-top: 3.5rem;
                width: 43%;
                position: relative; /* Asegura que la animación se mantenga en su posición */
                animation: silbido 2s infinite ease-in-out;
                transform-origin: bottom center;
              }
         
          }
        }
    }
}