@import '../../../scss/index.scss';
.sorprendido {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;
    h1 {
        font-family: $title;
        color: $corporativo;
    }
    .no_data {
        h5 {
            color: white;
            font-family: $gotica;
            text-align: center;
            padding-left: 5%;
            padding-right: 5%;
        }
        .row {
            margin-top: 40px;
            .card_image {
                position: relative;
                overflow: hidden;
                margin-bottom: 10px;
                img {
                    width: 100%;
                    border: 1px solid #752A95;
                    transition: filter 0.3s ease-in-out;
                    filter: brightness(120%) !important;
                }
                .content {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: radial-gradient(circle, transparent, rgba(0, 0, 0, 0.6));
                    z-index: 2;
                }
            }
        }
        @media screen and( max-width: $media-breackpoint-up-sm) {
            h5 {
                font-size: 15px;
            }
        }
    }
    .carousel {
        margin-left: auto;
        margin-right: auto;
        height: 70vh !important;
        width: 100% !important;
        .carousel-inner {
            .carousel-item,
            .active {
                img {
                    margin-left: auto !important;
                    margin-right: auto !important;
                    height: 70vh;
                    width: 100%;
                }
            }
        }
        @media screen and( max-width: $media-breackpoint-up-md-bootstrap) {
            margin-left: auto;
            margin-right: auto;
            height: 40vh !important;
            .carousel-inner {
                .carousel-item,
                .active {
                    img {
                        height: 40vh;
                    }
                }
            }
        }
        @media screen and( max-width: $media-breackpoint-up-sm) {
            height: 50vh !important;
            .carousel-inner {
                .carousel-item,
                .active {
                    img {
                        height: 40vh;
                    }
                }
            }
        }
        @media screen and( max-width: $media-breackpoint-up-xs) {
            height: 30vh !important;
            .carousel-inner {
                .carousel-item,
                .active {
                    img {
                        height: 30vh;
                    }
                }
            }
        }
    }
}