@import '../../scss/index.scss';
.container_resetPasword {
    width: 100%;
    height: 100vh;
    background-image: url('../../assets/jpg/fondo_morado.jpeg');
    background-repeat: no-repeat;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    justify-content: center;
    display: flex;
    align-items: center;
    .container {
        font-family: $title;
        .row {
            .container_form {
                background-color: white;
                padding: 30px;
                box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .4);
                border-radius: 10px;
                h1 {
                    text-align: center;
                    color: $corporativo;
                }
                .btn {
                    width: 100%;
                    background-color: $corporativo;
                    border-color: $corporativo;
                    &:hover {
                        background-color: $corporativo;
                        border-color: $corporativo;
                    }
                }
            }
        }
    }
}