@import '../../scss/index.scss';
.inactivo {
    margin-bottom: -17px;
    display: flex;
    align-items: center;
    p {
        padding: 2px;
        font-size: 11px;
        border: 1px solid $danger;
        color: $danger;
        font-family: $title;
    }
}