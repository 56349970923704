@import '../../scss/index.scss';
.navbar_top_admin {
    position: fixed;
    z-index: 1000;
    display: flex;
    box-shadow: 0 .3rem 1rem rgba(117, 42, 149, 1) !important;
    height: 80px !important;
    background-color: black !important;
    .container-fluid {
        .date_button {
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .section_logo {
            .fecha_hoy {
                background-color: #752a95;
                padding: 5px;
                border-radius: 5px;
                font-family: $title;
                color: white;
                margin-top: auto;
                margin-bottom: auto;
            }
        }
        padding: 3px 5%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .navBar {
            a {
                font-family: $title;
                font-size: 1.7 rem;
                color: var(--black);
                margin: 0 1rem;
                text-decoration: none;
                transition: 0.3s ease-in;
                &:hover {
                    color: blueviolet;
                    text-decoration: none;
                }
            }
        }
        .Contaier_navBar_principal {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            height: 60px;
            svg {
                width: 30px;
                color: $corporativo !important;
            }
            .datauser {
                padding-right: 10px;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                flex-direction: column;
                font-family: $gotica;
                font-size: 12px;
                p {
                    margin-top: auto;
                    font-weight: bold;
                    color: $corporativo;
                }
                span {
                    margin-top: -20px;
                    color: $font-grey;
                }
            }
        }
        .icons {
            .cuenta {
                background-color: white;
                border-radius: 3px;
                padding: 5px;
                border: 1px solid $corporativo;
                cursor: pointer;
                &:hover .opciones_user {
                    display: block;
                    padding: 5px;
                    margin-top: 10px;
                    background-color: white;
                    position: absolute;
                    li {
                        list-style: none;
                    }
                }
            }
            a {
                font-family: $title;
                font-size: 1.7 rem;
                color: var(--black);
                margin: 0 1rem;
                text-decoration: none;
                transition: 0.3s ease-in;
                &:hover {
                    color: blueviolet;
                    text-decoration: none;
                }
            }
            svg {
                margin-left: 1rem;
                width: 25px;
                height: 25px;
                transition: 0.3s ease-in;
                &:hover {
                    color: blueviolet;
                    fill: blueviolet;
                }
            }
            .user {
                cursor: pointer;
            }
            .carro {
                cursor: pointer;
            }
            .opciones_user {
                display: none;
                padding: 5px;
                margin-top: 10px;
                background-color: white;
                position: absolute;
                transition: .3s ease-in;
                li {
                    list-style: none;
                    a {
                        cursor: pointer;
                    }
                }
            }
            .mostrar {
                display: block;
                padding: 5px;
                margin-top: 10px;
                background-color: white;
                position: absolute;
                li {
                    list-style: none;
                    a {
                        cursor: pointer;
                    }
                }
            }
        }
        .menu_burguer {
            cursor: pointer;
            margin-right: 10px;
            svg {
                width: 25px;
                height: 25px;
                color: #752A95;
            }
        }
    }
    @media screen and( max-width: $media-breackpoint-up-lg) {
        .container {
            .logo {
                margin-left: 1rem;
            }
            .navBar {
                opacity: 0%;
                position: absolute;
                top: 100%;
                left: -120%;
                width: 100%;
                background: #eee;
                border-top: 1rem solid rgba(0, 0, 0, .1);
                transition: 0.5s ease-in;
                a {
                    font-size: 2rem;
                    background: white;
                    padding: 1.5 rem;
                    margin: 1.5 rem;
                    display: block;
                    text-align: center;
                }
            }
            .nav__active {
                transform: translateX(0);
                transition: 0.5s ease-in-out;
                opacity: 100%;
                left: 0%;
            }
        }
    }
}