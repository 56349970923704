@import '../../../scss/index.scss';
.container_profile_User {
    padding-top: 80px;
    .container_admin_footer_nav {
        display: flex;
        align-items: center;
        width: 100%;
        height: 50px;
        background-color: $corporativo;
        .container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            h5 {
                margin-top: auto;
                margin-bottom: auto;
                color: white;
                font-family: $title;
                margin-right: 20px;
            }
            .btn {
                font-family: $title;
                svg {
                    width: 15px;
                    margin-right: 10px;
                }
            }
        }
    }
    .titulos_paginas {
        h1 {
            font-family: $title;
            color: #7f00b2;
            transition: 0.3s ease-in;
        }
    }
    .container_progress {
        .progressBar {
            border-radius: 5px;
            width: 25%;
            height: 20px;
            background-color: $action;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            transition: 0.3s ease-in;
            p {
                margin-top: auto;
                margin-bottom: auto;
                margin-right: 5px;
                color: white;
                font-family: $title;
            }
        }
    }
    .container_form_pedido {
        margin-top: 10px;
        margin-bottom: 20px;
        padding-top: 10px;
        padding-bottom: 10px;
        border-radius: 5px;
        border: 1px solid #bc4ed8;
        font-family: $gotica;
    }
    .buttons_next_back {
        display: flex;
        justify-content: flex-start;
        padding-bottom: 20px;
        button {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 25%;
            background-color: $corporativo;
            border-color: $corporativo;
            svg {
                width: 20px;
                fill: white;
            }
            p {
                margin-right: 10px;
                font-family: $title;
                margin-top: auto;
                margin-bottom: auto;
            }
        }
        .btn_back {
            margin-right: 10px;
            p {
                padding-left: 10px;
                padding-right: 0px;
            }
        }
    }
    @media screen and( max-width: $media-breackpoint-up-sm) {
        .buttons_next_back {
            button {
                width: 50%;
            }
        }
    }
}