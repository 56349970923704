@import '../../scss/index.scss';
.contrato_container {
    padding-top: 10px;
    font-family: $gotica;
    background-color: white;
    h3 {
        font-family: $title;
        color: $corporativo;
    }
    p {
        margin-bottom: 0px;
        font-size: 13px;
        color: black !important;
    }
    .pagos_container {
        table {
            tbody {
                .adminDatos_container {
                    .admindatos {
                        font-size: 13px;
                        p {
                            margin-bottom: 0px;
                        }
                    }
                }
            }
        }
    }
    .row {
        h6,
        h5,
        p {
            span {
                font-weight: bold;
            }
        }
        .col-xl-3,
        .col-md-3,
        .col-sm-3 {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            img {
                margin-top: -10px;
                width: 100%;
            }
        }
        .col-xl-9,
        .col-md-9,
        .col-sm-9 {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .admindatos {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
        }
        /*  .comentarios_data {
            border-radius: 5px;
            padding-top: 5px;
            padding-bottom: 5px;
            border: 1px solid black;
        } */
    }
    .row_firma_usuario {
        padding-top: 50px;
        padding-bottom: 30px;
        .firma_usuario {
            border-radius: 5px;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            border: 1px solid black;
            padding-top: 100px;
        }
    }
    .titulo_lista {
        font-weight: bold;
    }
    @media screen and( max-width: $media-breackpoint-up-md) {
        .titulo_lista {
            font-size: 11px;
        }
        .row {
            h6 {
                font-size: 13px;
            }
            .admindatos {
                font-size: 10px;
                h6 {
                    font-size: 10px;
                }
                h5 {
                    font-size: 13px;
                }
            }
            .col-xl-3,
            .col-md-3,
            .col-sm-3 {
                img {
                    margin-top: -10px;
                    width: 100%;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }
    }
    @media screen and( max-width: $media-breackpoint-up-sm) {
        .row {
            .col-xl-3,
            .col-md-3,
            .col-sm-3 {
                img {
                    margin-top: -10px;
                    width: 100%;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }
    }
    .btn {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: $title;
        svg {
            width: 25px;
            fill: white;
            margin-right: 10px;
        }
    }
}