@import '../../scss/index.scss';
#label_switch {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .form-label {
        margin-top: auto;
        margin-bottom: auto;
        font-family: $gotica;
    }
    .form-check-input {
        &:checked {
            background-color: $corporativo;
            border-color: $corporativo;
        }
    }
}

.formulario {
    .row {
        .subtitulos {
            color: $corporativo;
            font-family: $gotica;
            font-weight: bold;
        }
        hr {
            color: $corporativo;
            border: 1px solid;
        }
    }
}