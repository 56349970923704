@import '../../../scss/index.scss';
.container__map {
    z-index: 1;
    background-color: black;
    width: 100%;
    height: 500px;
    .container {
        display: flex;
        height: 60px;
        p {
            width: 100%;
            margin-top: auto;
            margin-bottom: auto;
            font-family: $gotica;
            color: white;
            text-align: center;
            span {
                color: $corporativo;
                font-weight: bold;
            }
        }
    }
    .myMap {
        filter: grayscale(100%) invert(100%);
    }
    @media screen and( max-width: $media-breackpoint-up-md) {
        height: 500px;
        .container {
            height: 100px;
        }
    }
}