@import '../../scss/index.scss';
.container_carrito {
    height: 80vh;
    padding-top: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    img {
        width: 150px;
        height: auto;
    }
    h1 {
        color: $corporativo;
        font-family: $title;
    }
}