@import '../../../scss/index.scss';
.container_admin_footer {
    padding-top: 80px;
    .container_admin_footer_nav {
        display: flex;
        align-items: center;
        width: 100%;
        height: 50px;
        background-color: #752A95;
        .container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            h6 {
                margin-top: auto;
                margin-bottom: auto;
                color: white;
                font-family: $title;
                margin-right: 20px;
            }
            .btn {
                background-color: $corporativo;
                border: 1px solid $corporativo;
                font-family: $title;
                color: white;
                transition: 0.3s ease-in;
                svg {
                    width: 15px;
                }
                &:hover {
                    filter: brightness(110%);
                }
            }
        }
    }
    .container_admin_body {
        .row {
            .col-md-6,
            .col-sm-12,
            .col-xl-4,
            .col-xl-6 {
                padding-top: 5px;
                padding-bottom: 5px;
                .container_data {
                    padding-left: 10px;
                    padding-right: 10px;
                    .title {
                        border-top-right-radius: 10px;
                        border-top-left-radius: 10px;
                        border: 1px solid #752A95;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        background-color: #752A95;
                        p {
                            margin-top: auto;
                            margin-bottom: auto;
                            color: white;
                            font-family: $title;
                        }
                    }
                    .body {
                        border: 1px solid #752A95;
                        border-bottom-left-radius: 10px;
                        border-bottom-right-radius: 10px;
                        box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .1);
                        .table-responsive {
                            table {
                                color: white;
                                tbody {
                                    tr {
                                        font-family: $gotica;
                                        .form-control {
                                            width: 220px;
                                        }
                                        p {
                                            margin-top: auto;
                                            margin-bottom: auto;
                                            width: 85px;
                                        }
                                        .form-check-input {
                                            width: 50px;
                                            height: 30px;
                                            &:checked {
                                                background-color: #752A95;
                                                border-color: #752A95;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                textarea {
                    font-family: $gotica;
                    color: #752A95;
                }
            }
        }
    }
}