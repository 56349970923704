@import '../../../scss/index.scss';
.container_places_admin {
    padding-top: 80px;
    .container_admin_footer_nav {
        display: flex;
        align-items: center;
        width: 100%;
        height: 50px;
        background-color: #752A95;
        .container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            h6 {
                margin-top: auto;
                margin-bottom: auto;
                color: white;
                font-family: $title;
                margin-right: 20px;
            }
            .btn {
                background-color: $corporativo;
                border: 1px solid $corporativo;
                font-family: $title;
                color: white;
                transition: 0.3s ease-in;
                svg {
                    width: 15px;
                }
                &:hover {
                    filter: brightness(110%);
                }
            }
        }
    }
    .users_section_data {
        .table {
            color: white;
            tr {
                font-family: $gotica;
                .options_user_admin {
                    display: flex;
                    align-items: center;
                    height: 100%;
                    cursor: pointer;
                    svg {
                        width: 20px;
                    }
                    p {
                        font-family: $title;
                    }
                    .edit {
                        justify-content: center;
                        align-items: center;
                        display: flex;
                        flex-direction: column;
                        margin-right: 15px;
                        svg {
                            width: 25px;
                            color: $action;
                        }
                        p {
                            font-size: 10px;
                            color: $action;
                        }
                    }
                    .delete {
                        justify-content: center;
                        align-items: center;
                        display: flex;
                        flex-direction: column;
                        svg {
                            color: $danger;
                        }
                        p {
                            font-size: 10px;
                            color: $danger;
                        }
                    }
                    .galery {
                        a {
                            justify-content: center;
                            align-items: center;
                            display: flex;
                            flex-direction: column;
                            margin-right: 15px;
                            text-decoration: none;
                            svg {
                                width: 25px;
                                color: $success;
                            }
                            p {
                                font-size: 10px;
                                color: $success;
                            }
                        }
                    }
                    .packages {
                        a {
                            justify-content: center;
                            align-items: center;
                            display: flex;
                            flex-direction: column;
                            margin-right: 15px;
                            text-decoration: none;
                            svg {
                                width: 28px;
                                color: $amarillo;
                            }
                            p {
                                font-size: 10px;
                                color: $amarillo;
                            }
                        }
                    }
                }
                .options_user_name {
                    p {
                        margin-top: auto;
                        margin-bottom: auto;
                    }
                }
            }
        }
    }
}