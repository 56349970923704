@import '../../../scss/index.scss';
.formulario_eventos {
    font-family: $gotica;
    svg {
        fill: black !important;
        color: black !important;
    }
    .row {
        display: flex;
        justify-content: flex-start;
        button {
            margin-left: 1%;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 30%;
            background-color: $corporativo;
            border-color: $corporativo;
            svg {
                width: 20px;
                fill: white !important;
            }
            p {
                margin-right: 10px;
                font-family: $title;
                margin-top: auto;
                margin-bottom: auto;
            }
        }
        .form-label {
            color: $corporativo;
        }
    }
}