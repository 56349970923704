@import '../../scss/index.scss';
.container {
    .data_componente_lugar {
        .icono {
            display: flex;
            justify-content: center;
            align-items: center;
            svg {
                width: 30px;
                color: white;
            }
        }
        .datos {
            color: white;
            h4 {
                font-family: $title;
            }
            p {
                font-family: $gotica;
                font-size: 12px;
            }
            .telefono {
                font-size: 18px;
                font-weight: 800;
            }
        }
    }
    .buttons_data_lugares {
        justify-content: center;
        align-items: center;
        display: flex;
        a {
            border-radius: 3px;
            padding-left: 10px;
            padding-right: 10px;
            padding-top: 5px;
            padding-bottom: 5px;
            text-decoration: none;
            background-color: white;
            font-family: $title;
            color: $corporativo;
            font-weight: bold;
            font-size: 20px;
        }
    }
}