@import '../../../scss/index.scss';
.container_banner_perfil {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: radial-gradient(circle, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 1)), url("../../../assets/jpg/banner_profile_lugares.jpg");
    background-size: cover;
    background-position: center;
    .line_green {
        width: 100%;
        height: 3px;
        background: radial-gradient(circle, #69C528, transparent);
        filter: brightness(120%);
    }
    .container_data_banner {
        width: 100%;
        height: 150px;
        background: radial-gradient(circle, black, transparent);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .logo {
            font-size: 50px;
            font-family: $title;
            color: $corporativo;
            span {
                color: #752A95;
            }
        }
        .estado {
            font-size: 50px !important;
            padding-left: 10%;
            padding-right: 10%;
            text-align: center;
            color: white;
            text-transform: uppercase;
            font-weight: lighter;
            span {
                font-weight: bold;
                text-decoration: none;
                text-shadow: 0 0 10px #752A95, 0 0 20px #752A95, 0 0 30px #752A95;
            }
        }
    }
    .line_purple {
        width: 100%;
        height: 3px;
        background: radial-gradient(circle, #752A95, transparent);
        filter: brightness(120%);
    }
}

@media screen and( max-width: $media-breackpoint-up-sm) {
    .container_banner_perfil {
        height: 80vh;
        .container_data_banner {
            h1 {
                font-size: 35px !important;
            }
            @media screen and( max-width: $media-breackpoint-up-xs) {
                h1 {
                    font-size: 30px !important;
                }
            }
        }
    }
}