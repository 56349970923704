@import '../../../scss/index.scss';
.container_galleria_seccion {
    padding-top: 80px;
    .container_admin_footer_nav {
        display: flex;
        align-items: center;
        width: 100%;
        height: 50px;
        background-color: $corporativo;
        .container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            h5 {
                margin-top: auto;
                margin-bottom: auto;
                color: white;
                font-family: $title;
                margin-right: 20px;
            }
            .btn {
                font-family: $title;
                svg {
                    width: 15px;
                    margin-right: 10px;
                }
            }
        }
    }
    .container_body_galeria {
        .row {
            .col_galeria {
                margin-top: 10px;
                .delete_image {
                    border-radius: 5px !important;
                    display: flex;
                    align-items: center;
                    justify-content: space-between !important;
                    padding: 5px;
                    padding-right: 10px;
                    border-top: 1px solid #DBDBDB;
                    border-left: 1px solid #DBDBDB;
                    border-right: 1px solid #DBDBDB;
                    svg {
                        cursor: pointer;
                        width: 15px;
                        color: #DBDBDB;
                        transition: 0.3s ease-in;
                        &:hover {
                            color: $corporativo;
                        }
                    }
                    p {
                        margin-bottom: 0px;
                        text-transform: uppercase;
                        color: $corporativo;
                        font-family: $title;
                        font-size: 10px;
                    }
                }
                /*  img {
                    border-bottom-left-radius: 1.5%;
                    border-bottom-right-radius: 1.5%;
                } */
            }
        }
    }
}