@import '../../../scss/index.scss';
.nav_doggy {
    position: relative;
    box-shadow: 0px 12px 20px 0px black;
    z-index: 99;
    text-align: center; /* Centra el contenido horizontalmente */
    background: #30A0E1;
    .container_nav {
        padding-top: 20px;
        padding-bottom: 20px;
        display: flex;
        flex-direction: column; /* Coloca los elementos en columna */
        align-items: center; /* Centra horizontalmente */

        .logo_container {
            margin-bottom: 20px; /* Espacio entre el logo y los textos */
            .logoDoggy {
                width: 150px; /* Ajusta el tamaño del logo */
              }

              
          }
          .nav_options {
            margin-top: -10px;
            display: flex;
            gap: 20px; /* Espacio entre las opciones de navegación */
            justify-content: center; /* Centra las opciones horizontalmente */
        
            a {
                text-decoration: none;
                color: white;
                transition: 0.3s ease-in;
                font-family: $gotica;
                font-size: 1.4 rem;
                &:hover {
                    color: #552676; /* Cambia el color al pasar el mouse */
                    text-decoration: none;
                    text-shadow: 0 0 10px #552676, 0 0 20px #552676, 0 0 30px #552676;
                    /* Efecto de sombra de texto */
                    filter: brightness(150%);
                  }
              }
              
          }
      }
  }
  
 
  
 
  
  

  
 