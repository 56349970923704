@import '../../scss/index.scss';
.delete_user_form {
    p {
        font-family: $gotica;
        span {
            font-weight: bold;
        }
    }
    .buttons {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .btn {
            margin-right: 20px;
        }
    }
}