@import '../../scss/index.scss';
.table-responsive {
    .table {
        color: white;
        tbody {
            tr {
                font-family: $gotica;
                .options_user_admin {
                    display: flex;
                    align-items: center;
                    height: 100%;
                    svg {
                        margin-top: auto;
                        margin-bottom: auto;
                        width: 20px;
                        color: #f8f8fb;
                        cursor: pointer;
                    }
                    .edit {
                        justify-content: center;
                        align-items: center;
                        display: flex;
                        flex-direction: column;
                        margin-right: 10px;
                        svg {
                            width: 25px;
                            color: $action;
                        }
                        p {
                            font-size: 10px;
                            color: $action;
                        }
                    }
                    .delete {
                        justify-content: center;
                        align-items: center;
                        display: flex;
                        flex-direction: column;
                        margin-right: 10px;
                        svg {
                            color: $danger;
                        }
                        p {
                            font-size: 10px;
                            color: $danger;
                        }
                    }
                }
            }
        }
    }
}