@import '../../../scss/index.scss';
.eventos_user_container {
    padding-top: 80px;
    .users_section_nav {
        display: flex;
        align-items: center;
        width: 100%;
        height: 50px;
        background-color: #752A95;
        .container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            h5 {
                margin-top: auto;
                margin-bottom: auto;
                color: white;
                font-family: $title;
                margin-right: 20px;
            }
        }
    }
    .contaniner_form {
        margin-top: 10px;
        .Button_add {
            background-color: $action;
            border-color: $action;
            margin-right: 10px;
            font-family: $gotica;
            svg {
                margin-right: 5px;
                width: 15px;
                height: 15px;
            }
        }
        .Button_actualizar {
            font-family: $gotica;
            background-color: $corporativo;
            border: $corporativo;
        }
        .formulario {
            color: white;
        }
    }
}