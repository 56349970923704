@import './scss/index.scss';
.success {
    border: 1px solid $success;
    background-color: $background-success;
}

.error {
    border: 1px solid $danger;
    background-color: $background-error;
}

body {
    background-color: #000000;
}