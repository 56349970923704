@import '../../scss/index.scss';
.Container_form_evento_admin {
    form {
        .row {
            .funciones_producto {
                padding-top: 5px;
                display: flex;
                justify-content: space-between;
                color: $corporativo;
                font-family: $title;
                svg {
                    width: 12px;
                }
            }
        }
    }
    button {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $corporativo;
        border-color: $corporativo;
        font-family: $title;
        svg {
            width: 20px;
            margin-right: 10px;
        }
        &:hover,
        &:active {
            background-color: $corporativo;
            border-color: $corporativo;
        }
    }
}

.piezas {
    font-family: $title;
    display: flex;
    justify-content: center;
    align-items: center;
    button {
        background-color: $corporativo;
        border-color: $corporativo;
    }
    span {
        padding-left: 10px;
        padding-right: 10px;
        width: 50px;
        height: 35px;
        p {
            font-size: 30px;
            text-align: center;
            margin-left: auto;
            margin-right: auto;
        }
    }
}