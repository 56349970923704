@import '../../scss/index.scss';
.container_error {
    height: 80vh;
    padding-top: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    img {
        width: 250px;
        height: auto;
    }
    h1 {
        text-align: center;
        color: $corporativo;
        font-family: $title;
    }
}