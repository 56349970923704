@import '../../scss/index.scss';
.eventos_user_container {
    padding-top: 100px;
    .users_section_data {
        .texto {
            display: flex;
            justify-content: center;
            align-items: center;
            h1 {
                padding-top: 100px;
                padding-bottom: 100px;
                color: white;
                font-family: $title;
                text-decoration: none;
                text-shadow: 0 0 10px #69C528, 0 0 20px #69C528, 0 0 30px #69C528;
            }
        }
        .table {
            color: white;
            tr {
                font-family: $gotica;
                .options_user_admin {
                    display: flex;
                    align-items: center;
                    height: 100%;
                    svg {
                        margin-top: auto;
                        margin-bottom: auto;
                        width: 20px;
                        color: #f8f8fb;
                        cursor: pointer;
                    }
                    .visualizar {
                        justify-content: center;
                        align-items: center;
                        display: flex;
                        flex-direction: column;
                        margin-right: 10px;
                        svg {
                            width: 25px;
                            fill: $action;
                        }
                        p {
                            font-size: 10px;
                            color: $action;
                        }
                    }
                }
                td {
                    .abonos {
                        font-weight: bold;
                    }
                    .adeudo {
                        color: $rojo;
                    }
                }
                .total {
                    color: #2a593f;
                }
            }
        }
    }
}