@import "../../../scss/index.scss";

.carrouselInfo {
    background-color: #30a0e1;
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
  
    .track {
      display: flex;
      gap: 30px;
      animation: scroll 55s linear infinite;
      width: max-content; /* Evita que se acumulen los textos */
    }
  
    h3 {
      text-shadow: 1px 3px 8px black;
      color: #552676;
      font-style: italic;
      font-weight: 900;
      font-size: 3.25rem;
      line-height: 3.5rem;
      text-align: center;
      font-family: $titleDoggy;
    }
  }
  
  /* Animación para mover los h3 en loop */
  @keyframes scroll {
    from {
      transform: translateX(0%);
    }
    to {
      transform: translateX(-50%); /* Solo movemos la mitad para mantenerlo continuo */
    }
  }
  