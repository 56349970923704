@import '../../../scss/index.scss';
.container_paquetes {
    padding-top: 80px;
    .container_admin_footer_nav {
        display: flex;
        align-items: center;
        width: 100%;
        height: 50px;
        background-color: #7f00b2;
        .container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            h5 {
                margin-top: auto;
                margin-bottom: auto;
                color: white;
                font-family: $title;
                margin-right: 20px;
            }
            .btn {
                transition: 0.3s ease-in;
                font-family: $title;
                background-color: $corporativo;
                border-color: $corporativo;
                color: white;
                svg {
                    width: 15px;
                    margin-right: 10px;
                }
                &:hover {
                    filter: brightness(110%);
                }
            }
        }
    }
    .users_section_data {
        .row {
            .container_paquetes_body {
                margin-top: 10px;
                margin-bottom: 10px;
                .container {
                    background: whitesmoke;
                    color: $corporativo;
                    border-radius: 10px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    padding-top: 5px;
                    padding-bottom: 10px;
                    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .5);
                    h4 {
                        text-align: center;
                        margin: auto;
                        font-family: $title;
                    }
                    hr {
                        border: 1px solid;
                        width: 90%;
                    }
                    button {
                        transition: 0.3s ease-in;
                        margin-top: 10px;
                        font-family: $title;
                        padding-top: 5px;
                        padding-bottom: 5px;
                        color: white;
                        width: 90%;
                        border-radius: 5px;
                        border-color: $corporativo;
                        background-color: $corporativo;
                        svg {
                            width: 15px;
                        }
                        &:hover {
                            filter: brightness(110%);
                        }
                    }
                    .funciones_paquetes {
                        width: 100%;
                        display: flex;
                        justify-content: flex-end !important;
                        align-items: center;
                        #edit {
                            cursor: pointer;
                            border-radius: 3px;
                            width: 30px;
                            color: white;
                            background-color: $action;
                            padding: 5px;
                            margin-right: 5px;
                        }
                        #delete {
                            cursor: pointer;
                            border-radius: 3px;
                            width: 30px;
                            color: white;
                            background-color: $danger;
                            padding: 5px;
                            padding-left: 7px;
                            padding-right: 7px;
                        }
                    }
                }
                .list_descripcion {
                    display: flex;
                    justify-content: flex-start !important;
                    align-items: center;
                    width: 90%;
                    cursor: pointer;
                    svg {
                        width: 15px;
                        margin-right: 5px;
                        margin-bottom: 5px;
                        margin-top: 5px;
                    }
                    p {
                        margin-bottom: 5px;
                        margin-top: 5px;
                        font-family: $gotica;
                        font-size: 13px;
                        font-weight: bold;
                    }
                }
            }
        }
    }
}