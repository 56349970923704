@import '../../scss/index.scss';
.upload-avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .img-fluid {
        width: 90%;
        margin-bottom: 20px;
        height: auto;
        cursor: pointer;
        border-style: dotted;
        border-color: $corporativo;
    }
}

.form_container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    input {
        width: 90%;
    }
}

#button {
    transition: 0.3s ease-in;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
    background-color: $corporativo;
    font-family: $title;
    border-color: $corporativo;
    color: white;
    &:hover {
        background-color: $corporativo;
        border-color: $corporativo;
        &:hover {
            filter: brightness(110%);
        }
    }
}

#instruccion {
    font-family: $gotica;
    font-size: 13px;
}